import {
  Chip,
  EntityLink,
  Icon,
  Status,
} from '@lego/plugin-baseplate-core-components';
import { IconButton, Typography } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTableStyles } from './Table.styles';
import { TableColumnType, TableHeaderType, TableRowType } from './types';
import { formatNumber } from './utils';

type Props<T> = {
  headers: TableHeaderType[];
  data: TableRowType<T>[];
  emptyRows: number;
  selectedIds: string[];
  selectable?: boolean;
  loading?: boolean;
  isHidden?: boolean;
  openRowMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
    row: TableRowType<T>,
  ) => void;
  handleCheckboxClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void;
  stickyHeader?: boolean;
  hasMenu?: boolean;
  areCheckboxesDisabled?: boolean;
};

/**
 * Renders a cell based on its type.
 */
export const Cell = ({ cell }: { cell: TableColumnType }) => {
  const classes = useTableStyles();

  if (cell.isHidden) {
    return null;
  }

  if (cell.children) {
    return <TableCell>{cell.children}</TableCell>;
  }

  switch (cell.type) {
    case 'string':
      return <TableCell>{cell.value}</TableCell>;
    case 'number':
      return (
        <TableCell align="right">
          <span>{formatNumber(cell.value)}</span>
          {cell.unit && <Typography component="span"> {cell.unit}</Typography>}
        </TableCell>
      );
    case 'label':
      return (
        <TableCell>
          <Chip label={cell.value} color={cell.color} />
        </TableCell>
      );
    case 'entity':
      return (
        <TableCell>
          <EntityLink variant="inline-link" entityRef={cell.value} />
        </TableCell>
      );
    case 'status':
      return (
        <TableCell>
          <Status label={cell.value} status={cell.status} />
        </TableCell>
      );
    case 'custom':
      return <TableCell>{cell.value}</TableCell>;
    case 'labeledString':
      return <TableCell>{cell.label}</TableCell>;
    case 'stringWithIcon':
      return (
        <TableCell>
          <div className={classes.dataName}>
            {cell.icon?.toString() !== '' && (
              <img
                src={cell.icon?.toString()}
                alt="icon"
                className={classes.icon}
              />
            )}
            {cell.label}
          </div>
        </TableCell>
      );
    default:
      return null;
  }
};

/*
 * Renders all the table content.
 */
export function TableContent<T>({
  headers,
  data,
  openRowMenu,
  emptyRows,
  selectedIds,
  selectable,
  handleCheckboxClick,
  hasMenu,
  loading,
  stickyHeader,
  areCheckboxesDisabled = false,
}: Props<T>) {
  const classes = useTableStyles();

  return (
    <TableBody className={stickyHeader ? classes.stickyHeader : ''}>
      {loading &&
        new Array(20).fill(null).map((_, index) => (
          <TableRow key={index}>
            {headers.map(header => (
              <TableCell key={header.key}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      {data.map((row, ind) => (
        <TableRow
          key={ind}
          className={selectedIds.includes(row.id) ? classes.activeRow : ''}
        >
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                onChange={e => handleCheckboxClick(e, row.id)}
                checked={selectedIds.includes(row.id)}
                disabled={areCheckboxesDisabled}
              />
            </TableCell>
          )}
          {Object.entries(row.columns).map(([key, col], index) => (
            <Cell cell={col} key={`${key}-${index}`} />
          ))}
          {hasMenu && (
            <TableCell padding="checkbox">
              <IconButton
                id="basic-button"
                aria-haspopup="true"
                onClick={e => openRowMenu(e, row)}
                disabled={row.disableMenuAction}
              >
                <Icon icon="ellipsis-vertical" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 57 * emptyRows,
          }}
        >
          <TableCell
            colSpan={Object.keys(data[0].columns).length + (selectable ? 1 : 0)}
          />
        </TableRow>
      )}
    </TableBody>
  );
}
